// client/src/index.js
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));

const AppWrapper = () => {
  useEffect(() => {
    const tg = window.Telegram.WebApp;

    const applyTheme = () => {
      const themeParams = tg.themeParams;

      document.documentElement.style.setProperty('--tg-bg-color', themeParams.bg_color || '#FFFFFF');
      document.documentElement.style.setProperty('--tg-text-color', themeParams.text_color || '#000000');
      document.documentElement.style.setProperty('--tg-hint-color', themeParams.hint_color || '#6c757d');
      document.documentElement.style.setProperty('--tg-link-color', themeParams.link_color || '#1a73e8');
      document.documentElement.style.setProperty('--tg-button-color', themeParams.button_color || '#2481cc');
      document.documentElement.style.setProperty('--tg-button-text-color', themeParams.button_text_color || '#FFFFFF');
    };

    applyTheme();

    const onThemeChange = () => {
      applyTheme();
    };

    tg.onEvent('themeChanged', onThemeChange);

    return () => {
      tg.offEvent('themeChanged', onThemeChange);
    };
  }, []);

  return <App />;
};

root.render(<AppWrapper />);