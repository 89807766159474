// client/src/contexts/ShopContext.js
import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

export const ShopContext = createContext();

export const ShopProvider = ( { children } ) => {
    const [ shop, setShop ] = useState( null );

    useEffect( () => {
        // Получаем информацию о магазине
        axios.get( '/blinok/shops/_shop123/' ) // Замените '_shop123' на актуальный shopId
            .then( ( response ) => {
                setShop( response.data );
            } )
            .catch( ( error ) => {
                console.error( error );
            } );
    }, [] );

    return (
        <ShopContext.Provider value={ { shop } }>
            { children }
        </ShopContext.Provider>
    );
};