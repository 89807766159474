// client/src/components/AdminPanel.js
import React, { useContext, useState, useEffect } from 'react';
import { UserContext } from '../contexts/UserContext';
import { ShopContext } from '../contexts/ShopContext';
import axios from 'axios';
import {
    AdminPanelContainer,
    FormGroup,
    ProductListAdmin,
    ProductItemAdmin
} from './styles';

const AdminPanel = () => {
    const { isAdmin, adminMode, telegramUser } = useContext( UserContext );
    const [ menu, setMenu ] = useState( [] );
    const { shop } = useContext( ShopContext );
    const [ newProduct, setNewProduct ] = useState( {
        name: '',
        description: '',
        price: '',
        image: ''
    } );

    const headers = { 'X-User-Id': telegramUser ? telegramUser.id : '' };

    // Объявляем функцию addProduct перед ее использованием
    const addProduct = () => {
        axios.post( `/blinok/shops/${shop.id}/menu/`, newProduct, { headers } )
            .then( ( response ) => {
                setMenu( [ ...menu, response.data ] );
                setNewProduct( {
                    name: '',
                    description: '',
                    price: '',
                    image: ''
                } );
            } )
            .catch( ( error ) => {
                console.error( error );
            } );
    };

    const deleteProduct = ( productId ) => {
        axios.delete( `/shops/_shop123/menu/${productId}/`, { headers } )
            .then( () => {
                setMenu( menu.filter( ( product ) => product.id !== productId ) );
            } )
            .catch( ( error ) => {
                console.error( error );
            } );
    };

    useEffect( () => {
        if ( isAdmin && adminMode ) {
            axios.get( '/blinok/shops/_shop123/menu/' )
                .then( ( response ) => {
                    setMenu( response.data );
                } )
                .catch( ( error ) => {
                    console.error( error );
                } );
        }
    }, [ isAdmin, adminMode ] );

    useEffect( () => {
        const tg = window.Telegram.WebApp;

        if ( adminMode ) {
            tg.MainButton.text = 'Сохранить изменения';
            tg.MainButton.show();
            tg.MainButton.onClick( addProduct );
        } else {
            tg.MainButton.hide();
            tg.MainButton.offClick( addProduct );
        }

        return () => {
            tg.MainButton.offClick( addProduct );
        };
    }, [ adminMode, newProduct, addProduct ] );

    if ( !isAdmin || !adminMode ) {
        return null;
    }

    return (
        <AdminPanelContainer>
            <h2>Панель администратора</h2>
            {/* Форма для добавления продукта */ }
            <FormGroup>
                <label>Название:</label>
                <input
                    type="text"
                    value={ newProduct.name }
                    onChange={ ( e ) => setNewProduct( { ...newProduct, name: e.target.value } ) }
                    required
                />
            </FormGroup>
            <FormGroup>
                <label>Описание:</label>
                <textarea
                    value={ newProduct.description }
                    onChange={ ( e ) => setNewProduct( { ...newProduct, description: e.target.value } ) }
                    required
                ></textarea>
            </FormGroup>
            <FormGroup>
                <label>Цена:</label>
                <input
                    type="number"
                    value={ newProduct.price }
                    onChange={ ( e ) => setNewProduct( { ...newProduct, price: parseFloat( e.target.value ) } ) }
                    required
                />
            </FormGroup>
            <FormGroup>
                <label>Изображение (имя файла):</label>
                <input
                    type="text"
                    value={ newProduct.image }
                    onChange={ ( e ) => setNewProduct( { ...newProduct, image: e.target.value } ) }
                    required
                />
            </FormGroup>

            {/* Список продуктов */ }
            <ProductListAdmin>
                <h3>Текущие продукты</h3>
                { menu.map( ( product ) => (
                    <ProductItemAdmin key={ product.id }>
                        <span>{ product.name } - { product.price } ₽</span>
                        <button onClick={ () => deleteProduct( product.id ) }>Удалить</button>
                    </ProductItemAdmin>
                ) ) }
            </ProductListAdmin>
        </AdminPanelContainer>
    );
};

export default AdminPanel;