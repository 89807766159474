// client/src/components/styles.js
import styled from 'styled-components';
import { ReactComponent as CartIcon } from '../assets/icons/cart.svg'; // Импортируем CartIcon
import LogoAIcon from '../assets/images/logo.jpg';
export const LogoIcon = styled( LogoAIcon )`
    width: 24px;
    height: 24px;
    fill: var(--tg-text-color, #000000);
`;
 // Импортируем LogoIcon

export const AppContainer = styled.div`
  font-family: 'Roboto', sans-serif;
  padding: 10px;
  max-width: 600px;
  margin: 0 auto;
  background-color: var(--tg-bg-color, #FFFFFF);
  color: var(--tg-text-color, #000000);
`;

export const HeaderContainer = styled.header`
  text-align: center;
  margin-bottom: 20px;
`;

export const Logo = styled.h1`
  font-size: 2rem;
  color: var(--tg-link-color, #ff5722);
`;

export const ShopInfo = styled.div`
  text-align: center;
  margin-top: 5px;

  & > p {
    margin: 0;
    font-size: 0.9rem;
    color: var(--tg-hint-color, #6c757d);
  }
`;

export const ProductListContainer = styled.div`
  margin-bottom: 20px;
`;

export const ProductCategory = styled.div`
  margin-bottom: 20px;

  & > h2 {
    margin-bottom: 10px;
    font-size: 1.5rem;
    color: var(--tg-link-color, #ff5722);
  }
`;

export const ProductItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`;

export const ProductImage = styled.img`
  width: 70px;
  height: 70px;
  object-fit: cover;
  margin-right: 10px;
`;

export const ProductInfo = styled.div`
  flex: 1;
  color: var(--tg-text-color, #000000);

  & > h3 {
    margin: 0 0 5px 0;
    font-size: 1rem;
    color: var(--tg-text-color, #000000);
  }

  & > p {
    margin: 0 0 3px 0;
    font-size: 0.9rem;
    color: var(--tg-hint-color, #6c757d);
  }
`;

export const AddToCartButton = styled.button`
  padding: 5px 10px;
  background-color: var(--tg-button-color, #4caf50);
  color: var(--tg-button-text-color, #FFFFFF);
  border: none;
  cursor: pointer;
`;

export const SearchInput = styled.input`
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  margin-bottom: 20px;
  box-sizing: border-box;
  background-color: var(--tg-bg-color, #FFFFFF);
  color: var(--tg-text-color, #000000);
  border: 1px solid var(--tg-hint-color, #cccccc);
`;

export const CartContainer = styled.div`
  text-align: center;
  margin-top: 10px;

  & > p {
    font-size: 0.9rem;
    color: var(--tg-hint-color, #6c757d);
  }
`;

export const AdminPanelContainer = styled.div`
  margin-top: 20px;
  color: var(--tg-text-color, #000000);
`;

export const FormGroup = styled.div`
  margin-bottom: 15px;

  & > label {
    display: block;
    margin-bottom: 3px;
    font-size: 0.9rem;
    color: var(--tg-text-color, #000000);
  }

  & > input,
  & > textarea {
    width: 100%;
    padding: 8px;
    font-size: 0.9rem;
    box-sizing: border-box;
    background-color: var(--tg-bg-color, #FFFFFF);
    color: var(--tg-text-color, #000000);
    border: 1px solid var(--tg-hint-color, #cccccc);
  }
`;

export const ProductListAdmin = styled.div`
  margin-top: 15px;
`;

export const ProductItemAdmin = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;

  & > span {
    font-size: 0.9rem;
    color: var(--tg-text-color, #000000);
  }

  & > button {
    padding: 5px 10px;
    background-color: var(--tg-button-color, #f44336);
    color: var(--tg-button-text-color, #FFFFFF);
    border: none;
    cursor: pointer;
    font-size: 0.8rem;
  }
`;

export const CartCount = styled.span`
  position: absolute;
  top: -5px;
  right: -10px;
  background-color: var(--tg-button-color, #ff5722);
  color: var(--tg-button-text-color, #FFFFFF);
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 0.8rem;
`;


export const CloseButton = styled.button`
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  font-size: 2rem;
  color: var(--tg-text-color, #000000);
  cursor: pointer;
`;



export const EmptyCartMessage = styled.p`
  text-align: center;
  font-size: 1.2rem;
  color: var(--tg-hint-color, #6c757d);
`;

export const CheckoutButton = styled.button`
  width: 100%;
  padding: 15px;
  background-color: var(--tg-button-color, #ff9800);
  color: var(--tg-button-text-color, #FFFFFF);
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
`;

export const CartIconStyled = styled( CartIcon )`
  width: 24px;
  height: 24px;
  fill: var(--tg-text-color, #000000);
`;

// Обновляем `CartIconContainer` при необходимости
export const CartIconContainer = styled.div`
  position: relative;
  cursor: pointer;

  svg {
    width: 24px;
    height: 24px;
    fill: var(--tg-text-color, #000000);
  }
`;

export const OrderPreviewContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--tg-bg-color, #FFFFFF);
  color: var(--tg-text-color, #000000);
  overflow-y: auto;
  z-index: 1000;
  padding: 20px;
  box-sizing: border-box;
`;

export const OrderItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`;

export const OrderItemInfo = styled.div`
  flex: 1;
  min-width: 0;
  word-break: break-word;

  & > h3 {
    margin: 0 0 5px 0;
    font-size: 1rem;
  }

  & > p {
    margin: 0;
    font-size: 0.9rem;
    color: var(--tg-hint-color, #6c757d);
  }
`;

export const OrderItemActions = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;

  & > button {
    padding: 5px 10px;
    background-color: var(--tg-button-color, #4caf50);
    color: var(--tg-button-text-color, #FFFFFF);
    border: none;
    cursor: pointer;
    font-size: 1rem;
  }

  & > span {
    margin: 0 10px;
    font-size: 1rem;
  }
`;

export const InputField = styled.input`
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  margin-bottom: 15px;
  box-sizing: border-box;
  border: 1px solid var(--tg-hint-color, #cccccc);
  background-color: var(--tg-bg-color, #FFFFFF);
  color: var(--tg-text-color, #000000);
  border-radius: 5px;
  outline: none;

  &:focus {
    border-color: var(--tg-button-color, #4caf50);
  }
`;

// Добавляем стили для `ErrorMessage`
export const ErrorMessage = styled.p`
  margin: 0;
  color: red;
  font-size: 0.9rem;
`;