// client/src/contexts/UserContext.js
import React, { createContext, useState, useEffect } from 'react';

export const UserContext = createContext();

export const UserProvider = ( { children } ) => {
    const [ telegramUser, setTelegramUser ] = useState( null );
    const [ isAdmin, setIsAdmin ] = useState( false );
    const [ adminMode, setAdminMode ] = useState( false );

    useEffect( () => {
        if ( window.Telegram.WebApp ) {
            const initData = window.Telegram.WebApp.initDataUnsafe;

            if ( initData.user ) {
                setTelegramUser( initData.user );

                checkIfUserIsAdmin( initData.user );
            }
        }
    }, [] );

    const checkIfUserIsAdmin = ( user ) => {
        fetch( `/blinok/api/check-admin/${user.id}/` )
            .then( ( res ) => res.json() )
            .then( ( data ) => {
                setIsAdmin( data.isAdmin );
            } )
            .catch( ( err ) => {
                console.error( err );
            } );
    };

    const toggleAdminMode = () => {
        setAdminMode( !adminMode );
    };

    return (
        <UserContext.Provider
            value={ { telegramUser, isAdmin, adminMode, toggleAdminMode } }
        >
            { children }
        </UserContext.Provider>
    );
};