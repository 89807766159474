// client/src/components/ProductList.js
import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { CartContext } from '../contexts/CartContext';
import { ShopContext } from '../contexts/ShopContext';
import LogoAIcon from '../assets/images/logo.jpg';
import {
    ProductListContainer,
    ProductCategory,
    ProductItem,
    ProductImage,
    ProductInfo,
    AddToCartButton,
    SearchInput
} from './styles';

const ProductList = () => {
    const [ productsByCategory, setProductsByCategory ] = useState( {} );
    const [ allProducts, setAllProducts ] = useState( [] );
    const { addToCart } = useContext( CartContext );
    const { shop } = useContext( ShopContext );
    const [ searchQuery, setSearchQuery ] = useState( '' );

    const handleSearchChange = ( event ) => {
        setSearchQuery( event.target.value );
    };

    // Загружаем все продукты при загрузке компонента или при изменении магазина
    useEffect( () => {
        if ( shop ) {
            axios.get( `/blinok/shops/${shop.id}/menu` )
                .then( ( response ) => {
                    // Фильтруем продукты без цены
                    const productsWithPrice = response.data.filter( product => product.price && product.price > 0 );
                    setAllProducts( productsWithPrice );
                } )
                .catch( ( error ) => {
                    console.error( error );
                } );
        }
    }, [ shop ] );

    // Фильтруем и группируем продукты при изменении поискового запроса или списка всех продуктов
    useEffect( () => {
        // Фильтруем продукты по поисковому запросу
        const filteredProducts = allProducts.filter( product =>
            product.name.toLowerCase().includes( searchQuery.toLowerCase() ) ||
            ( product.description ? product.description.toLowerCase().includes( searchQuery.toLowerCase() ) : false )
        );

        // Группируем продукты по категориям
        const groupedProducts = filteredProducts.reduce( ( acc, product ) => {
            if ( !acc[ product.category ] ) {
                acc[ product.category ] = [];
            }
            acc[ product.category ].push( product );
            return acc;
        }, {} );
        setProductsByCategory( groupedProducts );
    }, [ searchQuery, allProducts ] );
    const handleImageError = ( e ) => {
        if ( e.target.src != LogoAIcon ) {
            e.target.src = LogoAIcon; // Замените путь на ваше fallback изображение
        }
    };
    return (
        <ProductListContainer>
            <SearchInput
                type="text"
                placeholder="Поиск товаров..."
                value={ searchQuery }
                onChange={ handleSearchChange }
            />
            { Object.keys( productsByCategory ).map( ( category ) => (
                <ProductCategory key={ category }>
                    <h2>{ category }</h2>
                    { productsByCategory[ category ].map( ( product ) => (
                        <ProductItem key={ product.id }>
                            <ProductImage src={ `${product.image}` } alt={ product.name } onError={handleImageError} />
                            <ProductInfo>
                                <h3>{ product.name }</h3>
                                <p>{ product.description }</p>
                                <p>Цена: { product.price } ₽</p>
                            </ProductInfo>
                            <AddToCartButton onClick={ () => addToCart( product ) }>
                                +
                            </AddToCartButton>
                        </ProductItem>
                    ) ) }
                </ProductCategory>
            ) ) }
        </ProductListContainer>
    );
};

export default ProductList;