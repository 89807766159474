// client/src/components/OrderPreview.js
import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { CartContext } from '../contexts/CartContext';
import { UserContext } from '../contexts/UserContext';
import { ShopContext } from '../contexts/ShopContext';
import {
    OrderPreviewContainer,
    OrderItem,
    OrderItemInfo,
    OrderItemActions,
    CloseButton,
    EmptyCartMessage,
    CheckoutButton,
    InputField,
    ErrorMessage
} from './styles';

const OrderPreview = ( { onClose } ) => {
    const { cart, clearCart, addToCart, removeFromCart } = useContext( CartContext );
    const { telegramUser } = useContext( UserContext );
    const { shop } = useContext( ShopContext );

    const [ address, setAddress ] = useState( '' );
    const [ phone, setPhone ] = useState( '' );
    const [ isPhoneValid, setIsPhoneValid ] = useState( true ); // Состояние для валидации номера телефона

    const totalAmount = cart.reduce( ( acc, item ) => acc + item.price * item.quantity, 0 );

    useEffect( () => {
        const tg = window.Telegram.WebApp;
        tg.MainButton.hide(); // Скрываем основную кнопку при открытом предпросмотре

        return () => {
            tg.MainButton.show();
        };
    }, [] );

    const validatePhone = ( phone ) => {
        const phoneRegex = /^(\+7|8)\d{10}$/; // Регулярное выражение для номеров телефона в формате +7XXXXXXXXXX или 8XXXXXXXXXX
        return phoneRegex.test( phone );
    };

    const handlePlaceOrder = () => {
        // Проверка валидации номера телефона перед отправкой
        if ( !validatePhone( phone ) ) {
            setIsPhoneValid( false );
            return; // Не отправляем заказ, если телефон невалиден
        }
        setIsPhoneValid( true ); // Если телефон валидный, скрываем сообщение об ошибке

        const orderData = {
            shopId: shop.id,
            products: cart.map( ( item ) => ( {
                id: item.id,
                quantity: item.quantity,
            } ) ),
            customerInfo: {
                name: telegramUser ? telegramUser.first_name : 'Гость',
                phone,
                address,
            },
        };

        const headers = {};
        if ( telegramUser ) {
            headers[ 'X-User-Id' ] = telegramUser.id;
        }

        axios
            .post( '/blinok/orders/', orderData, { headers } )
            .then( ( response ) => {
                window.Telegram.WebApp.close();
                clearCart();
            } )
            .catch( ( error ) => {
                console.error( error );
                alert( 'Ошибка при оформлении заказа' );
            } );
    };

    return (
        <OrderPreviewContainer>
            <CloseButton onClick={ onClose }>×</CloseButton>
            <h2>Ваш заказ</h2>
            { cart.length === 0 ? (
                <EmptyCartMessage>Ваша корзина пуста</EmptyCartMessage>
            ) : (
                <>
                    { cart.map( ( item ) => (
                        <OrderItem key={ item.id }>
                            <OrderItemInfo>
                                <h3>{ item.name }</h3>
                                <p>
                                    { item.price } ₽ x { item.quantity }
                                </p>
                            </OrderItemInfo>
                            <OrderItemActions>
                                <button onClick={ () => removeFromCart( item.id ) }>-</button>
                                <span>{ item.quantity }</span>
                                <button onClick={ () => addToCart( item ) }>+</button>
                            </OrderItemActions>
                        </OrderItem>
                    ) ) }
                    <p>Итого: { totalAmount } ₽</p>

                    {/* Поля для ввода адреса и телефона */ }
                    <InputField
                        type="text"
                        placeholder="Введите ваш адрес"
                        value={ address }
                        onChange={ ( e ) => setAddress( e.target.value ) }
                        minLength={ 10 } // Минимальная длина адреса
                        required
                    />
                    <InputField
                        type="tel"
                        placeholder="Введите ваш телефон"
                        value={ phone }
                        onChange={ ( e ) => setPhone( e.target.value ) }
                        required
                        style={ { borderColor: !isPhoneValid ? 'red' : '' } } // Красная граница, если телефон невалиден
                    />
                    { !isPhoneValid && <ErrorMessage>Введите корректный номер телефона (формат: +7XXXXXXXXXX или 8XXXXXXXXXX)</ErrorMessage> }

                    <CheckoutButton onClick={ handlePlaceOrder }>Подтвердить заказ</CheckoutButton>
                </>
            ) }
        </OrderPreviewContainer>
    );
};

export default OrderPreview;