// client/src/components/Cart.js
import React, { useContext, useEffect } from 'react';
import { CartContext } from '../contexts/CartContext';
import { ShopContext } from '../contexts/ShopContext';

const Cart = ({ onOpenOrderPreview }) => {
  const { cart } = useContext(CartContext);
  const { shop } = useContext(ShopContext);

  const totalAmount = cart.reduce((acc, item) => acc + item.price * item.quantity, 0);
  const minOrderAmount = shop ? shop.minOrderAmount : 0;

  useEffect(() => {
    const tg = window.Telegram.WebApp;

    const updateMainButton = () => {
      if (cart.length > 0) {
        if (totalAmount >= minOrderAmount) {
          tg.MainButton.setText('Оформить заказ');
          tg.MainButton.enable();
        } else {
          tg.MainButton.setText(`Минимальный заказ: ${minOrderAmount} ₽`);
          tg.MainButton.disable();
        }
        tg.MainButton.show();
        tg.MainButton.onClick(onOpenOrderPreview);
      } else {
        tg.MainButton.hide();
        tg.MainButton.offClick(onOpenOrderPreview);
      }
    };

    updateMainButton();

    return () => {
      tg.MainButton.offClick(onOpenOrderPreview);
    };
  }, [cart, totalAmount, minOrderAmount, onOpenOrderPreview]);

  return null; // Компонент не отображает UI, он управляет основной кнопкой
};

export default Cart;