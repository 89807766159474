// client/src/App.js
import React, { useState } from 'react';
import { UserProvider } from './contexts/UserContext';
import { ShopProvider } from './contexts/ShopContext';
import { CartProvider } from './contexts/CartContext';
import Header from './components/Header';
import ProductList from './components/ProductList';
import Cart from './components/Cart';
import AdminPanel from './components/AdminPanel';
import { AppContainer } from './components/styles';
import OrderPreview from './components/OrderPreview';



function App() {
  const [isOrderPreviewOpen, setIsOrderPreviewOpen] = useState(false);

  const handleOpenOrderPreview = () => {
    setIsOrderPreviewOpen(true);
  };

  const handleCloseOrderPreview = () => {
    setIsOrderPreviewOpen(false);
  };

  return (
    <UserProvider>
      <ShopProvider>
        <CartProvider>
          <AppContainer>
            <Header onCartClick={handleOpenOrderPreview} />
            <ProductList />
            <Cart onOpenOrderPreview={handleOpenOrderPreview} />
            {isOrderPreviewOpen && <OrderPreview onClose={handleCloseOrderPreview} />}
            <AdminPanel />
          </AppContainer>
        </CartProvider>
      </ShopProvider>
    </UserProvider>
  );
}

export default App;