// client/src/components/Header.js
import React, { useContext } from 'react';
import { ShopContext } from '../contexts/ShopContext';
import { CartContext } from '../contexts/CartContext';
import {
    HeaderContainer,
    Logo,
    CartIconContainer,
    CartIconStyled, // Импортируем CartIconStyled
    CartCount,
} from './styles';
import { UserContext } from '../contexts/UserContext';

const Header = ( { onCartClick } ) => {
    const { shop } = useContext( ShopContext );
    const { cart } = useContext( CartContext );
    const { toggleAdminMode } = useContext( UserContext );

    const totalItems = cart.reduce( ( acc, item ) => acc + item.quantity, 0 );

    return (
        <HeaderContainer>
            { shop && (
                <>
                    <button onClick={toggleAdminMode}>Toggle Mode</button>
                    <Logo>{ shop.name }</Logo>
                    <span>Часы работы: {shop.workingHours}</span>
                    <CartIconContainer onClick={ onCartClick }>
                        <CartIconStyled />
                        { totalItems > 0 && <CartCount>{ totalItems }</CartCount> }
                    </CartIconContainer>
                </>
            ) }
        </HeaderContainer>
    );
};

export default Header;